<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CCol col="6" style="font-size: 25px;">تعديل الفئة الفرعية</CCol>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <form @submit.prevent="UpdateSubCategory">
                                    <div class="form-group">
                                        <label style="padding:5px;">الاسم <span class="star">*</span></label>
                                        <input type="text" class="form-control" v-model="subCategory.name" required>
                                         <div v-if(errors.name) id="validateName" class="invalid-feedback">
                                          {{errors.name}}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label style="padding:5px;">الاسم باللغة العربية <span class="star">*</span></label>
                                        <input type="text" class="form-control" v-model="subCategory.name_ar" required>
                                    </div>

                                    <div class="form-group">
                                        <label style="padding:5px;">الصورة القديمة</label>
                                        <img v-bind:src="subCategory.image_full_path" style="height: 100px;width: 100px;"/>
                                    </div>
                                    <div class="form-group">
                                        <label style="padding:5px;">الصورةالجديدة</label>
                                        <input type="file" id="image" v-on:change="onFileChange"/>
                                        <div v-if(errors.image) id="validateImage" class="invalid-feedback">
                                          {{errors.image}}
                                        </div>
                                        <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="categories">
                                            الفئات
                                            <span class="star">*</span>
                                        </label>
                                        <multiselect class=" required " v-model="selected" :value="selected" id="categories" :options="Categories" :searchable="true"
                                            selectedLabel=" العنصر المحدد" deselectLabel="اضغط لإلغاء اختيار هذا العنصر" selectLabel="اضغط لإختيار هذا العنصر" :close-on-select="true"
                                            placeholder="اختر ..." label="name" track-by="name" required> <span slot="noOptions">اللائحة فارغة</span> <span slot="noResult">لا توجد نتيجة</span>
                                        </multiselect>
                                        <div v-if(errors.category_id) id="validateCategoryId" class="invalid-feedback">
                                          {{errors.category_id}}
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-success" >تحديث الفئة</button>
                                    &emsp;
                                    <CButton color="warning" @click="goBack">رجوع</CButton>
                                </form>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name : 'EditSubCategory',
        data() {
            return {
                subCategory: [],
                image:'',
                Categories: [],
                selected: '',
                errors: []
            }
        },
        created() {
            console.log(this.$route.params.id);
            this.$http
                .get(`${process.env.VUE_APP_URL}sub-categories/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.subCategory = response.data.data.sub_category;
                    this.selected = this.subCategory.category;
                });
            let categories = [];
            this.$http
                .get(`${process.env.VUE_APP_URL}categories`)
                .then((response) => {
                    $.each(response.data.data, function(key, value) {
                        categories.push({id:value.id,name:value.name})
                    });
                    this.Categories = categories;
                });
        },
        methods: {
            onFileChange(e){
//                console.log(e.target.files[0]);
                this.subCategory.image = e.target.files[0];
                this.image = e.target.files[0];
                console.log(this.image);
            },
            UpdateSubCategory(e) {
                let currentObj = this;
                let formData = new FormData();
                if (this.image) {
                  if (this.image.size > 5000000) {
                    e.preventDefault();
                    this.errors.push('image')
                    this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                    swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                    return 0;
                  } else {
                    formData.append("image", this.image);
                  }
                }
                formData.append('name', this.subCategory.name);
                formData.append('name_ar', this.subCategory.name_ar);
                formData.append('category_id', this.selected.id);
                formData.append('_method', 'PUT');
                this.$http
                    .post(`${process.env.VUE_APP_URL}sub-categories/${this.$route.params.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                    .then((response) => {
                        if(response.data.error)
                        {
                            currentObj.errors = response.data.data;
                        } else {
                            swal({ title:response.data.message, buttons: "تم"});
                            currentObj.$router.push({ path: "/sub-categories" });
                        }
                    });
            },
            goBack() {
                this.categoriesOpened ? this.$router.go(-1) : this.$router.push({path: '/sub-categories'})
            }
        }
    }
</script>
<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
 
h1, h2 {
  font-weight: normal;
}
 
ul {
  list-style-type: none;
  padding: 0;
}
 
li {
  display: inline-block;
  margin: 0 10px;
}
 
a {
  color: #42b983;
}
</style>